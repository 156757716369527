import React from "react";
import ResponsiveMedia from "utils/responsive_media";
import { Container, Box } from "components/style/layoutStyle";
import { TextBold, TextNormal } from "components/style/textStyle";
import { SquareButton } from "components/button";
import { ColoredBox } from "./style";
import TitleVideo from "./titleVideo";
import MoveToSignUp from "../../../utils/move_to_sign_up";
import { checkIsLogin } from "utils/common";
import moveToDashboard from "utils/move_to_dashboard";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { LanguageContext } from "templates/LocaleBase";

const VideoTitle = ({ data, bgColor, padding }) => {
    const { isMobile } = ResponsiveMedia();
    const isLogin = checkIsLogin();

    const { t, i18n } = useTranslation();

    const { isKo } = useContext(LanguageContext);

    return (
        <Container height={"auto"} alignitems={"center"} justifycontent={"center"} padding={padding} flexDirection={"column"}>
            <ColoredBox height={isMobile === "true" ? "410px" : "740px"} bgColor={bgColor || "#faf7ef"} />
            <Box flexDirection={"column"} padding={isMobile === "true" ? "60px 0" : "120px 0 80px"}>
                <TextBold type={isMobile === "true" ? "26" : isKo ? "42" : "44"} textAlign={"center"} margin={isMobile === "true" ? "0 0 15px" : "0 0 16px"} whiteSpace={isMobile === "true" ? "pre-line" : "none"}>
                    {isMobile && data.title_mobile ? t(data.title_mobile) : t(data.title)}
                </TextBold>
                <TextNormal type={isMobile === "true" ? "16" : isKo ? "20" : "24"} textAlign={"center"} margin={isMobile === "true" ? "0 0 30px" : "0 0 24px"}>
                    {isMobile && data.desc_mobile ? t(data.desc_mobile) : t(data.desc)}
                </TextNormal>
                <SquareButton
                    width={isMobile === "true" ? "180px" : "210px"}
                    fontSize={isMobile === "true" ? "16px" : isKo ? "18px" : "20px"}
                    color={"#fff"}
                    hColor={"#fff"}
                    aColor={"#fff"}
                    bgColor={"#713838"}
                    hBgColor={"#5d2e2e"}
                    aBgColor={"#451818"}
                    text={isLogin ? t("LOGIN_TITLE") : t("PROMOTION_LEFT_TITLE")}
                    onClickEvent={() => (isLogin ? moveToDashboard() : MoveToSignUp())}
                />
            </Box>
            <TitleVideo mp4={data.video[i18n.language]?.mp4} ogv={data.video[i18n.language]?.ogv} webm={data.video[i18n.language]?.webm} />
        </Container>
    );
};

export default VideoTitle;
