import React, { useContext } from "react";
import ResponsiveMedia from "utils/responsive_media";
import Card from "../../parts/card";
import { TextBold } from "components/style/textStyle";
import { Container, Box } from "components/style/layoutStyle";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "templates/LocaleBase";

const FeaturesCard = ({ data }) => {
    const { t, i18n } = useTranslation();
    const { isMobile } = ResponsiveMedia();
    const { isKo } = useContext(LanguageContext);

    return (
        <Container padding={isMobile === "true" ? "0 0 20px" : "0 0 200px"}>
            <Box padding={isMobile === "true" ? "0" : "0 100px"} flexDirection={"column"} gap={"20px 0"}>
                <TextBold type={isMobile === "true" ? "26" : isKo ? "36" : "40"} textAlign={"center"}>
                    {isMobile && data.title_mobile ? t(data.title_mobile) : t(data.title)}
                </TextBold>
                <Card
                    data={data.items}
                    titleSize={"22px"}
                    iconSize={isMobile === "true" ? "70px" : "104px"}
                    justifyContent={isMobile === "true" ? "center" : "space-between"}
                    gap={isMobile === "true" ? (i18n.language === "ko" ? "50px 8px" : "50px 0px") : "30px 8px"}
                    colCount={isMobile === "true" ? "2" : "3"}
                    height={isMobile ? "194px" : "280px"}
                    autoWidth={i18n.language !== "ko" && isMobile}
                />
            </Box>
        </Container>
    );
};

export default FeaturesCard;
