import React, { useState } from "react";
import TextTabButton from "components/button/textTabButton";
import { TabContainer } from "./style";
import { useTranslation } from "react-i18next";
import ResponsiveMedia from "utils/responsive_media";

const TextTab = ({ data, margin, gap, onClickEvent, initIndex = 0, ...style }) => {
    const { t } = useTranslation();
    const { isMobile } = ResponsiveMedia();

    const [active, setActive] = useState(initIndex);
    const handleTab = (index) => {
        setActive(index);
        onClickEvent && onClickEvent(index);
    };

    return (
        <TabContainer {...style} margin={margin} gap={gap}>
            {data.map((item, index) => {
                return (
                    <div key={index} onClick={() => handleTab(index)} style={{ flex: 1, display: "flex", justifyContent: "center" }}>
                        <TextTabButton title={t(isMobile && item.title_mobile ? item.title_mobile : item.title)} active={index === active} />
                    </div>
                );
            })}
        </TabContainer>
    );
};

export default TextTab;
