import styled from "styled-components";

export const TabContainer = styled.div`
    width: 100%;
    border-bottom: solid 1px #dddddd;
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: ${(props) => props.gap || "15%"}; */
    margin: ${(props) => props.margin || "0 0 48px"};
`;
