import React, { useState } from "react";

import { DescBox, DescWrapper } from "./style";
import { Container, Box } from "components/style/layoutStyle";
import { TextBold, TextNormal } from "components/style/textStyle";
import TextButton from "components/button/textButton";
import Image from "components/image/image";
import TextTab from "../textTab";
import { useTranslation } from "react-i18next";
import ResponsiveMedia from "utils/responsive_media";

const DescriptionTab = ({ data, bgColor }) => {
    const { t, i18n } = useTranslation();
    const { isMobile } = ResponsiveMedia();

    const [currentItem, setCurrentItem] = useState(data.items ? data.items[0] : { desc: data.desc, img: data.img });

    const handleCurrentItem = (index) => {
        setCurrentItem(data.items[index]);
    };

    const Desc = (props) => {
        const descData = props.data;

        const desc = t(descData.desc_mobile) ? t(descData.desc_mobile) : t(descData.desc);
        const img = descData.img_mobile ? descData.img_mobile : descData.img;

        return (
            <DescBox>
                <DescWrapper>
                    <TextNormal type={"16"} color={"#1d1d1d"} textAlign={"center"} margin={"0 0 20px"}>
                        {desc}
                    </TextNormal>
                    {!data.items && data.link && <TextButton margin={"0 0 40px"} data={data.link} />}
                    <Image filename={img} alt={t(desc)} style={{ width: "100%", height: "auto", maxWidth: "700px" }} />
                </DescWrapper>
            </DescBox>
        );
    };

    return (
        <Container bgColor={bgColor} padding={"80px 0 60px"} flexDirection={"column"}>
            <TextBold type={"26"} textAlign={"center"} margin={"0 0 32px"}>
                {isMobile ? t(data.title_mobile ? data.title_mobile : data.title) : t(data.title)}
            </TextBold>
            {data.items && (
                <>
                    {data.link && <TextButton margin={"0 0 40px"} data={data.link} />}
                    <Box justifycontent={"center"}>
                        <TextTab data={data.items} onClickEvent={handleCurrentItem} gap={i18n.language === "en" ? (data.items.length === 2 ? "15%" : "0") : "15%"} />
                    </Box>
                </>
            )}
            <Desc data={currentItem} />
        </Container>
    );
};

export default DescriptionTab;
