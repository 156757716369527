import React from "react";
import ResponsiveMedia from "utils/responsive_media";
import VideoTitle from "../../parts/videoTitle";

import DescriptionTab from "../../parts/description/descriptionTab";
import DescriptionAccordions from "../../parts/description/descriptionAccordion";
import FeaturesCard from "./featuresCard";

const Features = ({ title, features, sign, management, card }) => {
    const { isMobile } = ResponsiveMedia();
    return (
        <>
            <VideoTitle data={title} padding={isMobile === "true" ? "" : "0 0 87px"} />
            {isMobile === "true" ? (
                <>
                    <DescriptionTab data={features} />
                    <DescriptionTab data={sign} bgColor={"#f8f8f8"} />
                    <DescriptionTab data={management} />
                </>
            ) : (
                <>
                    <DescriptionAccordions data={features} />
                    <DescriptionAccordions data={sign} bgColor={"#f8f8f8"} reverse />
                    <DescriptionAccordions data={management} />
                </>
            )}
            <FeaturesCard data={card} />
        </>
    );
};

export default Features;
