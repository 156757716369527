// import { IC_CARD_CERTIFICATION, IC_CARD_CHAT, IC_CARD_CONTACTS, IC_CARD_HOLD, IC_CARD_SETTING, IC_CARD_TEMPLET, IC_CONTRACT_COMP, IC_CONTRACT_COMP_ACTIVE, IC_CONTRACT_PLUS, IC_CONTRACT_PLUS_ACTIVE, IC_CONTRACT_UPLOAD, IC_CONTRACT_UPLOAD_ACTIVE, IC_MANAGE_DOWN, IC_MANAGE_DOWN_ACTIVE, IC_MANAGE_FOLDER, IC_MANAGE_FOLDER_ACTIVE, IC_SIGN_STAMP, IC_SIGN_STAMP_ACTIVE, IC_SIGN_UPLOAD, IC_SIGN_UPLOAD_ACTIVE, IC_SIGN_WRITE, IC_SIGN_WRITE_ACTIVE } from "../media/icon";
// import { IMG_CONTRACT_COMP_ADIT, IMG_CONTRACT_DOCU_UPLOAD, IMG_CONTRACT_SIGNER_ADD, IMG_MANAGE_DOWNLOAD, IMG_MANAGE_DOWNLOAD_M, IMG_MANAGE_FOLDER, IMG_SIGN_DRAWING, IMG_SIGN_STAMP, IMG_SIGN_UPLLOAD } from "../media/images";

// import FEATURES_MP4 from 'images/video/video_landing_function_1.mp4';
// import FEATURES_OGV from 'images/video/video_landing_function_1.ogv';
// import FEATURES_WEBM from 'images/video/video_landing_function_1.webm';

// export const SERVICE_FEATURES_TITLE = {
//     title: '계약은 안전하게,\n과정은 간편하게.',
//     desc: '도뉴는 계약에 최적화된 서비스를 제공합니다.',
//     video: {
//         mp4: FEATURES_MP4,
//         ogv: FEATURES_OGV,
//         webm: FEATURES_WEBM,
//     }
// }

// export const SERVICE_FUNTIONS = {
//     title: '빠른 문서 업로드로\n단축된 계약 작성 시간',
//     items: [
//         {
//             title: '문서\n업로드',
//             desc: '로컬 및 클라우드 드라이브(구글 드라이브,드롭박스 등)로\n저장되어 있는 문서를 빠르게 업로드하세요.',
//             desc_mobile: '로컬 및 클라우드 드라이브\n(구글 드라이브,드롭박스 등)로\n저장되어 있는 문서를 빠르게 업로드하세요.',
//             img : IMG_CONTRACT_DOCU_UPLOAD,
//             icon: IC_CONTRACT_UPLOAD,
//             icon_act: IC_CONTRACT_UPLOAD_ACTIVE,
//         },
//         {
//             title: '참여자\n추가',
//             desc: '계약에 참여해야 할 서명자,참조자를\n이메일 혹은 전화번호로 추가 할 수 있습니다.',
//             img : IMG_CONTRACT_SIGNER_ADD,
//             icon: IC_CONTRACT_PLUS,
//             icon_act: IC_CONTRACT_PLUS_ACTIVE,
//         },
//         {
//             title: '입력 도구\n설정',
//             desc: '각 서명자가 입력해야 할 내용과 서명란의 위치를\n자유롭게 설정할 수 있습니다.',
//             img : IMG_CONTRACT_COMP_ADIT,
//             icon: IC_CONTRACT_COMP,
//             icon_act: IC_CONTRACT_COMP_ACTIVE,
//         },
//     ]
// }

// export const SERVICE_SIGNATURE = {
//     title: '단 몇 초만에 생성하는\n맞춤형 서명/도장',
//     link: {
//         type: 'path',
//         title: '나만의 서명/도장 만들기',
//         url: '/service/signature',
//     },
//     items: [
//         {
//             title: '서명\n만들기',
//             desc: '화면에 직접 서명을 그리거나 도뉴에서 제공하는\n서명 템플릿으로 쉽게 만들 수 있습니다.',
//             img : IMG_SIGN_DRAWING,
//             icon: IC_SIGN_WRITE,
//             icon_act: IC_SIGN_WRITE_ACTIVE,
//         },
//         {
//             title: '서명/도장\n업로드',
//             desc: '기존에 사용하던 서명/도장을 파일로\n업로드 하실 수 있습니다.',
//             img : IMG_SIGN_UPLLOAD,
//             icon: IC_SIGN_UPLOAD,
//             icon_act: IC_SIGN_UPLOAD_ACTIVE,
//         },
//         {
//             title: '온라인 도장\n만들기',
//             desc: '도뉴에서 제공하는 도장 템플릿으로\n실제 직인과 같은 도장 이미지를 만들 수 있습니다.',
//             img : IMG_SIGN_STAMP,
//             icon: IC_SIGN_STAMP,
//             icon_act: IC_SIGN_STAMP_ACTIVE,
//         }
//     ],
// }

// export const SERVICE_MANAGEMENT = {
//     title: '폴더로 관리되는\n문서 보관함',
//     items:[
//         {
//             title: '폴더 관리',
//             desc: '수 많은 계약서를 폴더로 쉽게 분류하고\n보다 체계적으로 관리할 수 있습니다.',
//             img : IMG_MANAGE_FOLDER,
//             icon: IC_MANAGE_FOLDER,
//             icon_act: IC_MANAGE_FOLDER_ACTIVE,
//         },
//         {
//             title: '문서 다운로드',
//             desc: '도뉴에 저장된 계약서 및 첨부파일 등\n모든 문서를 다운로드 할 수 있습니다.',
//             img : IMG_MANAGE_DOWNLOAD,
//             img_mobile : IMG_MANAGE_DOWNLOAD_M,
//             icon: IC_MANAGE_DOWN,
//             icon_act: IC_MANAGE_DOWN_ACTIVE,
//         }
//     ],
// }

// export const SERVICE_FEATURES_CARD = {
//     title: '도뉴는 기업에\n최적화 된 기능을 제공합니다.',
//     items: [
//         {
//             img: IC_CARD_CONTACTS,
//             title: '주소록',
//             desc: '자주 계약하는\n상대방의 정보 저장',
//         },
//         {
//             img: IC_CARD_CHAT,
//             title: '채팅방',
//             desc: '실시간 채팅으로\n계약 협의',
//         },
//         {
//             img: IC_CARD_HOLD,
//             title: '잠금 설정',
//             desc: '암호 설정으로\n문서 잠금',
//         },
//         {
//             img: IC_CARD_SETTING,
//             title: '기한 설정',
//             desc: '서명 기한 설정으로\n철저한 계약 일정 관리',
//         },
//         {
//             img: IC_CARD_TEMPLET,
//             title: '템플릿',
//             desc: '자주 사용하는\n문서 저장',
//         },
//         {
//             img: IC_CARD_CERTIFICATION,
//             title: '추가 인증',
//             desc: '서명자 추가 인증으로\n문서 열람 가능',
//         }
//     ],
// }

import {
    IC_CARD_CERTIFICATION,
    IC_CARD_CHAT,
    IC_CARD_CONTACTS,
    IC_CARD_HOLD,
    IC_CARD_SETTING,
    IC_CARD_TEMPLET,
    IC_CONTRACT_COMP,
    IC_CONTRACT_COMP_ACTIVE,
    IC_CONTRACT_PLUS,
    IC_CONTRACT_PLUS_ACTIVE,
    IC_CONTRACT_UPLOAD,
    IC_CONTRACT_UPLOAD_ACTIVE,
    IC_MANAGE_DOWN,
    IC_MANAGE_DOWN_ACTIVE,
    IC_MANAGE_FOLDER,
    IC_MANAGE_FOLDER_ACTIVE,
    IC_SIGN_STAMP,
    IC_SIGN_STAMP_ACTIVE,
    IC_SIGN_UPLOAD,
    IC_SIGN_UPLOAD_ACTIVE,
    IC_SIGN_WRITE,
    IC_SIGN_WRITE_ACTIVE
} from "../media/icon";
import { IMG_CONTRACT_COMP_ADIT, IMG_CONTRACT_DOCU_UPLOAD, IMG_CONTRACT_SIGNER_ADD, IMG_MANAGE_DOWNLOAD, IMG_MANAGE_DOWNLOAD_M, IMG_MANAGE_FOLDER, IMG_SIGN_DRAWING, IMG_SIGN_STAMP, IMG_SIGN_UPLLOAD } from "../media/images";

import FEATURES_MP4 from "images/video/video_landing_function_1.mp4";
import FEATURES_OGV from "images/video/video_landing_function_1.ogv";
import FEATURES_WEBM from "images/video/video_landing_function_1.webm";

import FEATURES_MP4_EN from "images/video/video_landing_function_1_en.mp4";
import FEATURES_OGV_EN from "images/video/video_landing_function_1_en.mp4";
import FEATURES_WEBM_EN from "images/video/video_landing_function_1_en.mp4";

export const SERVICE_FEATURES_TITLE = {
    title: "SERVICE_FEATURES_TITLE_TITLE",
    title_mobile: "SERVICE_FEATURES_TITLE_TITLE_MOBILE",
    desc: "SERVICE_FEATURES_TITLE_DESC",
    video: {
        ko: {
            mp4: FEATURES_MP4,
            ogv: FEATURES_OGV,
            webm: FEATURES_WEBM
        },
        en: {
            mp4: FEATURES_MP4_EN,
            ogv: FEATURES_OGV_EN,
            webm: FEATURES_WEBM_EN
        }
    }
};

export const SERVICE_FUNTIONS = {
    title: "SERVICE_FUNTIONS_TITLE",
    items: [
        {
            title: "SERVICE_FUNTIONS_ITEMS_1_TITLE",
            desc: "SERVICE_FUNTIONS_ITEMS_1_DESC",
            desc_mobile: "SERVICE_FUNTIONS_ITEMS_1_DESC_MOBILE",
            img: IMG_CONTRACT_DOCU_UPLOAD,
            icon: IC_CONTRACT_UPLOAD,
            icon_act: IC_CONTRACT_UPLOAD_ACTIVE
        },
        {
            title: "SERVICE_FUNTIONS_ITEMS_2_TITLE",
            desc: "SERVICE_FUNTIONS_ITEMS_2_DESC",
            img: IMG_CONTRACT_SIGNER_ADD,
            icon: IC_CONTRACT_PLUS,
            icon_act: IC_CONTRACT_PLUS_ACTIVE
        },
        {
            title: "SERVICE_FUNTIONS_ITEMS_3_TITLE",
            desc: "SERVICE_FUNTIONS_ITEMS_3_DESC",
            img: IMG_CONTRACT_COMP_ADIT,
            icon: IC_CONTRACT_COMP,
            icon_act: IC_CONTRACT_COMP_ACTIVE
        }
    ]
};

export const SERVICE_SIGNATURE = {
    title: "SERVICE_SIGNATURE_TITLE",
    title_mobile: "SERVICE_SIGNATURE_TITLE",
    link: {
        type: "path",
        title: "SERVICE_SIGNATURE_LINK_TITLE",
        url: "/service/signature"
    },
    items: [
        {
            title: "SERVICE_SIGNATURE_ITEM_1_TITLE",
            desc: "SERVICE_SIGNATURE_ITEM_1_DESC",
            img: IMG_SIGN_DRAWING,
            icon: IC_SIGN_WRITE,
            icon_act: IC_SIGN_WRITE_ACTIVE
        },
        {
            title: "SERVICE_SIGNATURE_ITEM_2_TITLE",
            desc: "SERVICE_SIGNATURE_ITEM_2_DESC",
            img: IMG_SIGN_UPLLOAD,
            icon: IC_SIGN_UPLOAD,
            icon_act: IC_SIGN_UPLOAD_ACTIVE
        },
        {
            title: "SERVICE_SIGNATURE_ITEM_3_TITLE",
            desc: "SERVICE_SIGNATURE_ITEM_3_DESC",
            img: IMG_SIGN_STAMP,
            icon: IC_SIGN_STAMP,
            icon_act: IC_SIGN_STAMP_ACTIVE
        }
    ]
};

export const SERVICE_MANAGEMENT = {
    title: "SERVICE_MANAGEMENT_TITLE",
    title_mobile: "SERVICE_MANAGEMENT_TITLE_MOBILE",
    items: [
        {
            title: "SERVICE_MANAGEMENT_ITEM_1_TITLE",
            desc: "SERVICE_MANAGEMENT_ITEM_1_DESC",
            img: IMG_MANAGE_FOLDER,
            icon: IC_MANAGE_FOLDER,
            icon_act: IC_MANAGE_FOLDER_ACTIVE
        },
        {
            title: "SERVICE_MANAGEMENT_ITEM_2_TITLE",
            desc: "SERVICE_MANAGEMENT_ITEM_2_DESC",
            img: IMG_MANAGE_DOWNLOAD,
            img_mobile: IMG_MANAGE_DOWNLOAD_M,
            icon: IC_MANAGE_DOWN,
            icon_act: IC_MANAGE_DOWN_ACTIVE
        }
    ]
};

export const SERVICE_FEATURES_CARD = {
    title: "SERVICE_FEATURES_CARD_TITLE",
    title_mobile: "SERVICE_FEATURES_CARD_TITLE_MOBILE",
    items: [
        {
            img: IC_CARD_CONTACTS,
            title: "SERVICE_FEATURES_CARD_ITEM_1_TITLE",
            desc: "SERVICE_FEATURES_CARD_ITEM_1_DESC"
        },
        {
            img: IC_CARD_CHAT,
            title: "SERVICE_FEATURES_CARD_ITEM_2_TITLE",
            desc: "SERVICE_FEATURES_CARD_ITEM_2_DESC"
        },
        {
            img: IC_CARD_HOLD,
            title: "SERVICE_FEATURES_CARD_ITEM_3_TITLE",
            desc: "SERVICE_FEATURES_CARD_ITEM_3_DESC"
        },
        {
            img: IC_CARD_SETTING,
            title: "SERVICE_FEATURES_CARD_ITEM_4_TITLE",
            desc: "SERVICE_FEATURES_CARD_ITEM_4_DESC"
        },
        {
            img: IC_CARD_TEMPLET,
            title: "SERVICE_FEATURES_CARD_ITEM_5_TITLE",
            desc: "SERVICE_FEATURES_CARD_ITEM_5_DESC"
        },
        {
            img: IC_CARD_CERTIFICATION,
            title: "SERVICE_FEATURES_CARD_ITEM_6_TITLE",
            desc: "SERVICE_FEATURES_CARD_ITEM_6_DESC"
        }
    ]
};
