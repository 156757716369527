import React from "react";
import { TextTabButtonWrapper, TabButtonText } from "./style";

const TextTabButton = ({ title, active }) => {
    return (
        <TextTabButtonWrapper active={active}>
            <TabButtonText>{title}</TabButtonText>
        </TextTabButtonWrapper>
    );
};

export default TextTabButton;
