import * as React from "react";

import Seo from "components/seo";
import LayoutBanner from "../../templates/layoutBanner";
import Features from "../../organisms/service/features";
import { SERVICE_FEATURES_CARD, SERVICE_FEATURES_TITLE, SERVICE_FUNTIONS, SERVICE_MANAGEMENT, SERVICE_SIGNATURE } from "../../constants/data/service_features";
import { SERVICE_FEATURES_META, SERVICE_FEATURES_META_EN } from "../../constants/data/meta_data";
import { graphql } from "gatsby";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const FeaturesPage = () => {
    const { i18n } = useTranslation();

    const seoData = useMemo(() => {
        if (i18n.language === "en") return SERVICE_FEATURES_META_EN;

        return SERVICE_FEATURES_META;
    }, [i18n.language]);

    return (
        <LayoutBanner>
            <Seo title={seoData.title} description={seoData.description} canonical={seoData.canonical} kakaoKeyword={seoData.kakao} />
            <Features title={SERVICE_FEATURES_TITLE} features={SERVICE_FUNTIONS} sign={SERVICE_SIGNATURE} management={SERVICE_MANAGEMENT} card={SERVICE_FEATURES_CARD} />
        </LayoutBanner>
    );
};

export default FeaturesPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
