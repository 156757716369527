import styled from "styled-components";

export const VideoBox = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${props => props.padding || '0 20px'};
    margin: ${props => props.margin};

    ${props => props.mobile? `
        > div {
            border-radius: 4px;       
        }
    ` :
    `
        > div {
            border-radius: 10px;       
        }
    `
    }
`

export const VideoWrapper = styled.div`
    width: 100%;
    height: 100%;
    max-width: 904px;
    max-width: ${props => props.maxWidth || '968px'};
    min-width: ${props => props.minWidth || '335px'};
    max-height: ${props => props.maxHeight || '560px'};
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.10);
    display: flex;
    align-items: flex-end;
    justify-content: start;
    flex-direction: column;
    border: none;
`

export const VideoBarWrapper = styled.div`
    width: 100%;
    height: 100%;
`

export const Video = styled.video.attrs(props => ({
    preload: 'auto',
    muted: 'muted',
    autoPlay: 'autoplay',
    loop: 'loop',
    controls: false,
    poster: 10,
}))`
    user-select: none;
    width: 100%;
    border: none;
    border-radius: ${props => props.mobile? `4px` :`10px`};
`;