import React from "react";

import { Video, VideoBox, VideoWrapper } from "components/style/videoStyle";

const TitleVideo = ({mp4, ogv, webm}) => {
    return (
        <VideoBox>
            <VideoWrapper>
                <Video
                    playsInline >
                    <source src={mp4} type='video/mp4' />
                    <source src={ogv} type='video/ogv' />
                    <source src={webm} type='video/webm' />
                </Video>
            </VideoWrapper>
        </VideoBox>
    )
}

export default TitleVideo;