import styled from "styled-components";

export const ColoredBox = styled.div`
    background-color: ${props => props.bgColor};
    width: 100%;
    height: ${props => props.height};
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
`