import React, { useEffect, useState } from "react";

import { Container, Box, DescImageWrapper } from "components/style/layoutStyle";
import Image from "components/image/image";
import Description from "./description";
import Accordion from "../accordion/accordion";
import { useTranslation } from "react-i18next";

const DescriptionAccordions = ({ data, bgColor, imgToTop, reverse }) => {
    const { t } = useTranslation();

    const [active, setActive] = useState(0);

    const handleAccordion = (index) => {
        setActive(index);
    };

    return (
        <Container padding={"130px 40px"} bgColor={bgColor} height={"auto"} alignitems={"flex-start"}>
            <Box gap={"100px"} alignitems={imgToTop ? "flex-start" : "center"} flexDirection={reverse ? "row-reverse" : "row"}>
                <DescImageWrapper width={"100%"} height={"100%"}>
                    <Image filename={data.items[active].img} alt={data.items[active].title} style={{ maxHeight: "436px" }} />
                </DescImageWrapper>
                <Box flexDirection={"column"} margin={reverse ? "0 0 0 35px" : "0 35px 0 0"} style={{ flex: 1.5 }}>
                    <Description padding={"0 10px 0"} alignitems={reverse ? "flex-start" : "flex-end"} textAlign={reverse ? "left" : "right"} type={"normal"} title={t(data.title)} link={data.link} />
                    <Accordion border data={data.items} padding={data.link ? "26px 0 0" : ""} jutifyContent={reverse ? "flex-start" : "flex-end"} onClickEvent={handleAccordion} alignitems={"flex-start"} />
                </Box>
            </Box>
        </Container>
    );
};

export default DescriptionAccordions;
